import React from 'react';
import File from '../../../extras/File';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const IMAIEF = () => {

    const navigate = useNavigate();

    const images = [
         { id: 1, name: 'imaief2022_05', image: 'publicaciones/boletines_inegi/IMAIEF/imaief2022_05.png', file: 'publicaciones/boletines_inegi/IMAIEF/imaief2022_05.pdf', type: 'PDF' },
         { id: 2, name: 'imaief2022_06', image: 'publicaciones/boletines_inegi/IMAIEF/imaief2022_06.png', file: 'publicaciones/boletines_inegi/IMAIEF/imaief2022_06.pdf', type: 'PDF' },
         { id: 3, name: 'imaief2022_07', image: 'publicaciones/boletines_inegi/IMAIEF/imaief2022_07.png', file: 'publicaciones/boletines_inegi/IMAIEF/imaief2022_07.pdf', type: 'PDF' },
         { id: 4, name: 'imaief2022_08', image: 'publicaciones/boletines_inegi/IMAIEF/imaief2022_08.png', file: 'publicaciones/boletines_inegi/IMAIEF/imaief2022_08.pdf', type: 'PDF' },
         { id: 5, name: 'imaief2022_09', image: 'publicaciones/boletines_inegi/IMAIEF/imaief2022_09.png', file: 'publicaciones/boletines_inegi/IMAIEF/imaief2022_09.pdf', type: 'PDF' },
         { id: 6, name: 'imaief2022_10', image: 'publicaciones/boletines_inegi/IMAIEF/imaief2022_10.png', file: 'publicaciones/boletines_inegi/IMAIEF/imaief2022_10.pdf', type: 'PDF' },
         { id: 7, name: 'imaief2022_11', image: 'publicaciones/boletines_inegi/IMAIEF/imaief2022_11.png', file: 'publicaciones/boletines_inegi/IMAIEF/imaief2022_11.pdf', type: 'PDF' },
         { id: 8, name: 'imaief2022_12', image: 'publicaciones/boletines_inegi/IMAIEF/imaief2022_12.png', file: 'publicaciones/boletines_inegi/IMAIEF/imaief2022_12.pdf', type: 'PDF' },
         { id: 9, name: 'imaief2023_01', image: 'publicaciones/boletines_inegi/IMAIEF/imaief2023_01.png', file: 'publicaciones/boletines_inegi/IMAIEF/imaief2023_01.pdf', type: 'PDF' },
         { id: 10, name: 'imaief2023_02', image: 'publicaciones/boletines_inegi/IMAIEF/imaief2023_02.png', file: 'publicaciones/boletines_inegi/IMAIEF/imaief2023_02.pdf', type: 'PDF' },
         { id: 11, name: 'imaief2023_03', image: 'publicaciones/boletines_inegi/IMAIEF/imaief2023_03.png', file: 'publicaciones/boletines_inegi/IMAIEF/imaief2023_03.pdf', type: 'PDF' },
         { id: 12, name: 'imaief2023_04', image: 'publicaciones/boletines_inegi/IMAIEF/imaief2023_04.png', file: 'publicaciones/boletines_inegi/IMAIEF/imaief2023_04.pdf', type: 'PDF' },
         { id: 13, name: 'imaief2023_05', image: 'publicaciones/boletines_inegi/IMAIEF/imaief2023_05.png', file: 'publicaciones/boletines_inegi/IMAIEF/imaief2023_05.pdf', type: 'PDF' },
         { id: 14, name: 'imaief2023_06', image: 'publicaciones/boletines_inegi/IMAIEF/imaief2023_06.png', file: 'publicaciones/boletines_inegi/IMAIEF/imaief2023_06.pdf', type: 'PDF' },
         { id: 15, name: 'imaief2023_07', image: 'publicaciones/boletines_inegi/IMAIEF/imaief2023_07.png', file: 'publicaciones/boletines_inegi/IMAIEF/imaief2023_07.pdf', type: 'PDF' },
         { id: 16, name: 'imaief2023_08', image: 'publicaciones/boletines_inegi/IMAIEF/imaief2023_08.png', file: 'publicaciones/boletines_inegi/IMAIEF/imaief2023_08.pdf', type: 'PDF' },
         { id: 17, name: 'imaief2023_09', image: 'publicaciones/boletines_inegi/IMAIEF/imaief2023_09.png', file: 'publicaciones/boletines_inegi/IMAIEF/imaief2023_09.pdf', type: 'PDF' },
         { id: 18, name: 'imaief2023_10', image: 'publicaciones/boletines_inegi/IMAIEF/imaief2023_10.png', file: 'publicaciones/boletines_inegi/IMAIEF/imaief2023_10.pdf', type: 'PDF' },
         { id: 19, name: 'imaief2023_11', image: 'publicaciones/boletines_inegi/IMAIEF/imaief2023_11.png', file: 'publicaciones/boletines_inegi/IMAIEF/imaief2023_11.pdf', type: 'PDF' },
         { id: 20, name: 'imaief2023_12', image: 'publicaciones/boletines_inegi/IMAIEF/imaief2023_12.png', file: 'publicaciones/boletines_inegi/IMAIEF/imaief2023_12.pdf', type: 'PDF' },
         { id: 21, name: 'imaief2024_01', image: 'publicaciones/boletines_inegi/IMAIEF/imaief2024_01.png', file: 'publicaciones/boletines_inegi/IMAIEF/imaief2024_01.pdf', type: 'PDF' },
         { id: 22, name: 'imaief2024_02', image: 'publicaciones/boletines_inegi/IMAIEF/imaief2024_02.png', file: 'publicaciones/boletines_inegi/IMAIEF/imaief2024_02.pdf', type: 'PDF' },
         { id: 23, name: 'imaief2024_03', image: 'publicaciones/boletines_inegi/IMAIEF/imaief2024_03.png', file: 'publicaciones/boletines_inegi/IMAIEF/imaief2024_03.pdf', type: 'PDF' },
         { id: 24, name: 'imaief2024_04', image: 'publicaciones/boletines_inegi/IMAIEF/imaief2024_04.png', file: 'publicaciones/boletines_inegi/IMAIEF/imaief2024_04.pdf', type: 'PDF' },
         { id: 25, name: 'imaief2024_05', image: 'publicaciones/boletines_inegi/IMAIEF/imaief2024_05.png', file: 'publicaciones/boletines_inegi/IMAIEF/imaief2024_05.pdf', type: 'PDF' }
    ];

    return (
        <div className='container'>
            <br />
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>Exportaciones por entidad federativa</h2>
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='col-lg-2' style={{ textAlign: 'left' }}>
                    <button type='button' className='btn btn-outline-secondary btn-block' onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faArrowLeft} /> Regresar
                    </button>
                </div>
                <div className='col-lg-10'></div>
            </div>
            <br />
            <div className='row'>
                {images.map((i) => {
                    return (
                        <File doc={i} key={i.id} />
                    );
                })}
            </div>
            <br /><br /><br /><br /><br />
        </div>
    );
}

export default IMAIEF;