import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from './components/layouts/Footer';
import Contacto from './components/cieg/Contacto';
import Header from './components/layouts/Header';
import ZacatecasEnCifras from './components/zacatecas-en-cifras/ZacatecasEnCifras';
import OcupacionEmpleo from './components/zacatecas-en-cifras/graficas/ocupacion_empleo/OcupacionEmpleo';
import Demografia from './components/zacatecas-en-cifras/graficas/demografia/Demografia';
import Migracion from './components/zacatecas-en-cifras/graficas/migracion/Migracion';
import Mineria from './components/zacatecas-en-cifras/graficas/mineria/Mineria';
import Turismo from './components/zacatecas-en-cifras/graficas/turismo/Turismo';
import AgriculturaGanaderia from './components/zacatecas-en-cifras/graficas/agricultura_ganaderia/AgriculturaGanaderia';
import SeguridadGobierno from './components/zacatecas-en-cifras/graficas/seguridad_gobierno/SeguridadGobierno';
import Economia from './components/zacatecas-en-cifras/graficas/economia/Economia';
import Educacion from './components/zacatecas-en-cifras/graficas/educacion/Educacion';
import Presentacion from './components/cieg/Presentacion';
import Juridico from './components/cieg/Juridico';
import Pdf from './components/cieg/Pdf';
import Convenio from './components/cieg/ceieg/Convenio';
import Integrantes from './components/cieg/ceieg/Integrantes';
import Peeg from './components/cieg/ceieg/Peeg';
import Pat from './components/cieg/ceieg/Pat';
import Revista from './components/cieg/publicaciones/Revista';
import Estadisticas from './components/cieg/normatividad/Estadisticas';
import Geografica from './components/cieg/normatividad/Geografica';
import MapasTematicos from './components/cieg/publicaciones/MapasTematicos';
import FichasMunicipales from './components/cieg/publicaciones/FichasMuncipales';
import InfoGeoMun from './components/cieg/publicaciones/InfoGeoMun';
import FichasRegionales from './components/cieg/publicaciones/FichasRegionales';
import PanoramaSociodemografico from './components/cieg/publicaciones/PanoramaSociodemografico';
import PanoramaSociodemografico2020 from './components/cieg/publicaciones/PanoramaSociodemografico2020';
import PanoramaSocioDemograficoMunicipio2020 from './components/cieg/publicaciones/PanoramaSocioDemograficoMunicipio2020';
import Coepo from './components/cieg/publicaciones/Coepo';
import InfoGeoReg from './components/cieg/publicaciones/InfoGeoReg';
import DocumentosAnalisis from './components/cieg/publicaciones/documentos_analisis/DocumentosAnalisis';
  import Agricultura from './components/cieg/publicaciones/documentos_analisis/Agricultura';
  import DocEconomia from './components/cieg/publicaciones/documentos_analisis/Economia';
  import Gobierno from './components/cieg/publicaciones/documentos_analisis/Gobierno';
  import Poblacion from './components/cieg/publicaciones/documentos_analisis/Poblacion';
  import Pobreza from './components/cieg/publicaciones/documentos_analisis/Pobreza';
  import Salud from './components/cieg/publicaciones/documentos_analisis/Salud';
  import Seguridad from './components/cieg/publicaciones/documentos_analisis/Seguridad';
  import Tecnologia from './components/cieg/publicaciones/documentos_analisis/Tecnologia';
  import Zap from './components/cieg/publicaciones/documentos_analisis/Zap';

import Zap2022 from './components/cieg/publicaciones/Zap2022';
import Zap2023 from './components/cieg/publicaciones/Zap2023';
import Zap2024 from './components/cieg/publicaciones/Zap2024';

import Sesiones from './components/cieg/ceieg/Sesiones';
import Boletin from './components/cieg/publicaciones/boletines/Boletin';
import ITAEE2021 from './components/cieg/publicaciones/boletines/ITAEE2021';
import ITAEE2022 from './components/cieg/publicaciones/boletines/ITAEE2022';
import ITAEE2023 from './components/cieg/publicaciones/boletines/ITAEE2023';
import ENOE2021 from './components/cieg/publicaciones/boletines/ENOE2021';
import ENOE2022 from './components/cieg/publicaciones/boletines/ENOE2022';
import ENOE2023 from './components/cieg/publicaciones/boletines/ENOE2023';
import ETEF2021 from './components/cieg/publicaciones/boletines/ETEF2021';
import ETEF2022 from './components/cieg/publicaciones/boletines/ETEF2022';
import ETEF2023 from './components/cieg/publicaciones/boletines/ETEF2023';
import IMAIEF from './components/cieg/publicaciones/boletines/IMAIEF';
import PIB from './components/cieg/publicaciones/boletines/PIB';

import ESTADISTICAS from './components/cieg/publicaciones/boletines/ESTADISTICAS';
import FichasZap from './components/cieg/publicaciones/FichasZap';
import Croquis from './components/cieg/publicaciones/croquis/Croquis';
import CroquisCarta from './components/cieg/publicaciones/croquis/Carta';
import CroquisDobleCarta from './components/cieg/publicaciones/croquis/DobleCarta';

import Revistas from './components/cieg/publicaciones/revistas/Revistas';
import ConcilacionDemografica from './components/cieg/publicaciones/documentos_analisis/ConcilacionDemografica';

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
      <Header />
        <Routes>
          <Route path="/" element={<ZacatecasEnCifras />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="/presentacion" element={<Presentacion />} />
          <Route path="/marco-juridico" element={<Juridico />} />
          <Route path="/pdf/:file" element={<Pdf />} />

          {/* Publicaciones */}
          <Route path="/pdf/marco_juridico/:file" element={<Pdf />} />
          <Route path="/pdf/publicaciones/mapas_tematicos/:file" element={<Pdf />} />
          <Route path="/pdf/publicaciones/:file" element={<Pdf />} />
          <Route path="/pdf/publicaciones/fichas_municipales/:file" element={<Pdf />} />
          <Route path="/pdf/publicaciones/informacion_geografica_municipal/:file" element={<Pdf />} />
          <Route path="/pdf/publicaciones/fichas_regionales/:file" element={<Pdf />} />
          <Route path="/pdf/publicaciones/coepo/:file" element={<Pdf />} />
          <Route path="/pdf/publicaciones/informacion_geografica_regional/:file" element={<Pdf />} />
          <Route path="/pdf/publicaciones/croquis_municipal/carta/:file" element={<Pdf />} />
          <Route path="/pdf/publicaciones/croquis_municipal/doble-carta/:file" element={<Pdf />} />

          <Route path="/pdf/publicaciones/zap2022/:file" element={<Pdf />} />
          <Route path="/pdf/publicaciones/zap2023/:file" element={<Pdf />} />
          <Route path="/pdf/publicaciones/zap2024/:file" element={<Pdf />} />
          <Route path="/pdf/publicaciones/panorama-sociodemografico-municipal-2020/:file" element={<Pdf />} />
          
            
            {/* Documentos de Análisis */}
            <Route path="/pdf/publicaciones/documentos_analisis/agricultura/:file" element={<Pdf />} />
            <Route path="/pdf/publicaciones/documentos_analisis/economia/:file" element={<Pdf />} />
            <Route path="/pdf/publicaciones/documentos_analisis/gobierno/:file" element={<Pdf />} />
            <Route path="/pdf/publicaciones/documentos_analisis/poblacion/:file" element={<Pdf />} />
            <Route path="/pdf/publicaciones/documentos_analisis/pobreza/:file" element={<Pdf />} />
            <Route path="/pdf/publicaciones/documentos_analisis/salud/:file" element={<Pdf />} />
            <Route path="/pdf/publicaciones/documentos_analisis/seguridad-publica/:file" element={<Pdf />} />
            <Route path="/pdf/publicaciones/documentos_analisis/tecnologia/:file" element={<Pdf />} />
            <Route path="/pdf/publicaciones/documentos_analisis/zap/:file" element={<Pdf />} />
            <Route path="/pdf/publicaciones/documentos_analisis/conciliacion-demografica/:file" element={<Pdf />} />


            {/* Boletines de INEGI */}
            <Route path="/pdf/publicaciones/boletines_inegi/ITAEE_2021/:file" element={<Pdf />} />
            <Route path="/pdf/publicaciones/boletines_inegi/ITAEE_2022/:file" element={<Pdf />} />
            <Route path="/pdf/publicaciones/boletines_inegi/ITAEE_2023/:file" element={<Pdf />} />
            <Route path="/pdf/publicaciones/boletines_inegi/ENOE_ZAC_2021/:file" element={<Pdf />} />
            <Route path="/pdf/publicaciones/boletines_inegi/ENOE_ZAC_2022/:file" element={<Pdf />} />
            <Route path="/pdf/publicaciones/boletines_inegi/ENOE_ZAC_2023/:file" element={<Pdf />} />
            <Route path="/pdf/publicaciones/boletines_inegi/ETEF2021/:file" element={<Pdf />} />
            <Route path="/pdf/publicaciones/boletines_inegi/ETEF2022/:file" element={<Pdf />} />
            <Route path="/pdf/publicaciones/boletines_inegi/ETEF2023/:file" element={<Pdf />} />
            <Route path="/pdf/publicaciones/boletines_inegi/IMAIEF/:file" element={<Pdf />} />
            <Route path="/pdf/publicaciones/boletines_inegi/PIB/:file" element={<Pdf />} />
            <Route path="/pdf/publicaciones/revistas/:file" element={<Pdf />} />
            <Route path="/pdf/publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO/:file" element={<Pdf />} />


          <Route path="/pdf/normatividad/tecnicas_estrategicas/:file" element={<Pdf />} />
          <Route path="/pdf/normatividad/tecnicas_geograficas/:file" element={<Pdf />} />
          <Route path="/pdf/ceieg/:file" element={<Pdf />} />
          <Route path="/pdf/ceieg/sesiones_ordinarias/:file" element={<Pdf />} />

          <Route path="/ocupacion-y-empleo" element={<OcupacionEmpleo />} />
          <Route path="/demografia" element={<Demografia />} />
          <Route path="/migracion" element={<Migracion />} />
          <Route path="/mineria" element={<Mineria />} />
          <Route path="/turismo" element={<Turismo />} />
          <Route path="/agricultura_ganaderia" element={<AgriculturaGanaderia />} />
          <Route path="/seguridad_gobierno" element={<SeguridadGobierno />} />
          <Route path="/economia" element={<Economia />} />
          <Route path="/educacion" element={<Educacion />} />
          
          <Route path="/ceieg/convenio" element={<Convenio />} />
          <Route path="/ceieg/integrantes" element={<Integrantes />} />
          <Route path="/ceieg/peeg" element={<Peeg />} />
          <Route path="/ceieg/pat" element={<Pat />} />
          <Route path="/ceieg/sesiones_ordinarias" element={<Sesiones />} />
          
          
          <Route path="/publicaciones/revistas" element={<Revistas />} />
          <Route path="/publicaciones/revista" element={<Revista />} />
          <Route path="/publicaciones/mapas_tematicos" element={<MapasTematicos />} />
          <Route path="/publicaciones/fichas_municipales" element={<FichasMunicipales />} />
          <Route path="/publicaciones/informacion_geografica_municipal" element={<InfoGeoMun />} />
          <Route path="/publicaciones/fichas_regionales" element={<FichasRegionales />} />
          <Route path="/publicaciones/panorama-sociodemografico" element={<PanoramaSociodemografico />} />
          <Route path="/publicaciones/panorama-sociodemografico-2020" element={<PanoramaSociodemografico2020 />} />
          <Route path="/publicaciones/panorama-sociodemografico-municipal-2020" element={<PanoramaSocioDemograficoMunicipio2020 />} />

          <Route path="/publicaciones/coepo" element={<Coepo />} />
          <Route path="/publicaciones/informacion_geografica_regional" element={<InfoGeoReg />} />

          <Route path="/publicaciones/zap_2022" element={<Zap2022 />} />
          <Route path="/publicaciones/zap_2023" element={<Zap2023 />} />
          <Route path="/publicaciones/zap_2024" element={<Zap2024 />} />


          {/* Croquis Municipales */}
          <Route path="/publicaciones/croquis_municipales" element={<Croquis />} />
          <Route path="/publicaciones/croquis_municipales/carta" element={<CroquisCarta />} />
          <Route path="/publicaciones/croquis_municipales/doble-carta" element={<CroquisDobleCarta />} />

          <Route path="/publicaciones/documentos_analisis" element={<DocumentosAnalisis />} />
            <Route path="/publicaciones/documentos_analisis/agricultura" element={<Agricultura />} />
            <Route path="/publicaciones/documentos_analisis/economia" element={<DocEconomia />} />
            <Route path="/publicaciones/documentos_analisis/gobierno" element={<Gobierno />} />
            <Route path="/publicaciones/documentos_analisis/poblacion" element={<Poblacion />} />
            <Route path="/publicaciones/documentos_analisis/pobreza" element={<Pobreza />} />
            <Route path="/publicaciones/documentos_analisis/salud" element={<Salud />} />
            <Route path="/publicaciones/documentos_analisis/seguridad-publica" element={<Seguridad />} />
            <Route path="/publicaciones/documentos_analisis/tecnologia" element={<Tecnologia />} />
            <Route path="/publicaciones/documentos_analisis/zap" element={<Zap />} />
            <Route path="/publicaciones/documentos_analisis/conciliacion-demografica" element={<ConcilacionDemografica />} />

          <Route path="/publicaciones/boletines_inegi" element={<Boletin />} />
            <Route path="/publicaciones/boletines_inegi/ITAEE_2021" element={<ITAEE2021 />} />
            <Route path="/publicaciones/boletines_inegi/ITAEE_2022" element={<ITAEE2022 />} />
            <Route path="/publicaciones/boletines_inegi/ITAEE_2023" element={<ITAEE2023 />} />
            <Route path="/publicaciones/boletines_inegi/ENOE_ZAC_2021" element={<ENOE2021 />} />
            <Route path="/publicaciones/boletines_inegi/ENOE_ZAC_2022" element={<ENOE2022 />} />
            <Route path="/publicaciones/boletines_inegi/ENOE_ZAC_2023" element={<ENOE2023 />} />
            <Route path="/publicaciones/boletines_inegi/ETEF2021" element={<ETEF2021 />} />
            <Route path="/publicaciones/boletines_inegi/ETEF2022" element={<ETEF2022 />} />
            <Route path="/publicaciones/boletines_inegi/ETEF2023" element={<ETEF2023 />} />
            <Route path="/publicaciones/boletines_inegi/IMAIEF" element={<IMAIEF />} />
            <Route path="/publicaciones/boletines_inegi/PIB" element={<PIB />} />

            <Route path="/publicaciones/boletines_inegi/ESTADISTICAS_A_PROPOSITO" element={<ESTADISTICAS />} />

            
          <Route path="/publicaciones/fichas_zap" element={<FichasZap />} />
          
          
          <Route path="/normatividad/estadistica" element={<Estadisticas />} />
          <Route path="/normatividad/geografica" element={<Geografica />} />
          
        </Routes>
      </BrowserRouter>
      <Footer />
    </React.Fragment>
  );
}

export default App;
